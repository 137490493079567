import React, { useState, useRef, useContext } from 'react';

import { useIntl } from 'react-intl';
import { RendererProps } from '../RendererProps';

import { Button } from '../../../Button';
import { Box } from '../../../Box';
import { Menu } from '../../../Menu';
import { Text } from '../../../Text';
import { ActionKey } from '../../types';
import { Skeleton } from '../../../Skeleton';
import { Tooltip } from '../../../Tooltip';
import messages from './messages';
import { TD } from '../../../Table';
import { Icons } from '../../../Icons';
import { DataGridContext } from '../../DataGridContext';

type MenuItem = {
  label: string;
  actionKey: ActionKey;
  danger?: boolean;
};

type Mode =
  | 'draft'
  | 'inProgress'
  | 'noProgressChart'
  | 'prepareConversation'
  | 'prepareConversationNoProgressChart';

type Common = {
  menuItems: MenuItem[];
  onSelect: (actionKey: string) => void;
  hideMenu?: boolean;
};

type ActionsProps = (
  | {
      mode: 'prepareConversation' | 'prepareConversationNoProgressChart';
      // TODO remove after talkingpoints cleanup
      showNotesInsteadOfTalkingPoints: boolean;
      talkingPointsCount?: number;
      notesCount: number;
    }
  | {
      mode: 'draft' | 'inProgress' | 'noProgressChart';
      // TODO remove after talkingpoints cleanup
      talkingPointsCount?: never;
      notesCount?: never;
      showNotesInsteadOfTalkingPoints?: never;
    }
) &
  Common;

const Dash = () => (
  <Box pl={4} pr={4}>
    <Text color="grey-dark">-</Text>
  </Box>
);

const Actions = ({
  onSelect,
  menuItems,
  mode,
  hideMenu,
  talkingPointsCount,
  notesCount,
  showNotesInsteadOfTalkingPoints,
}: ActionsProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const anchorEl = useRef();
  const { formatMessage } = useIntl();

  const handleActionSelect = (actionKey: string) => {
    setIsMenuOpen(false);
    onSelect(actionKey);
  };

  const getProgressComponent = (mode: Mode) => {
    switch (mode) {
      case 'inProgress':
      case 'prepareConversation':
        return (
          <Tooltip tooltipContent={formatMessage(messages.viewUpdates)}>
            <Button
              startIcon={Icons.Updates}
              variant="text"
              size="l"
              onClick={() => handleActionSelect('progress')}
              aria-label="cell action open progress update"
            />
          </Tooltip>
        );
      case 'draft':
        return (
          <Text color="grey-dark" fontSize={14}>
            {formatMessage(messages.draft)}
          </Text>
        );
      case 'noProgressChart':
      case 'prepareConversationNoProgressChart':
        return <Dash />;
    }
  };

  return (
    <TD>
      <Box display="flex" justifyContent="space-evenly" alignItems="center">
        {mode === 'prepareConversation' ||
        mode === 'prepareConversationNoProgressChart' ? (
          <>
            <Tooltip
              tooltipContent={formatMessage(
                showNotesInsteadOfTalkingPoints
                  ? messages.notes
                  : messages.talkingPoints
              )}
            >
              <Box
                width="56px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  startIcon={
                    showNotesInsteadOfTalkingPoints
                      ? Icons.Notes
                      : Icons.TalkingPoint
                  }
                  variant="text"
                  size="l"
                  onClick={() => handleActionSelect('talking_point')}
                  aria-label="cell action open talking points"
                />
              </Box>
            </Tooltip>
            <Text color="grey-dark" fontSize={14} ml={1} mr={6}>
              {(showNotesInsteadOfTalkingPoints
                ? notesCount
                : talkingPointsCount) || '-'}
            </Text>
          </>
        ) : null}
        <Box width="56px" display="flex" justifyContent="center">
          {getProgressComponent(mode)}
        </Box>
        {!hideMenu && (
          <Box width="56px" display="flex" justifyContent="center">
            {menuItems.length ? (
              <>
                <Tooltip tooltipContent={formatMessage(messages.moreOptions)}>
                  <Box>
                    <Button
                      onClick={() => setIsMenuOpen(!isMenuOpen)}
                      startIcon={Icons.Overflow}
                      variant="text"
                      size="l"
                      ref={anchorEl}
                      aria-label="cell action more options"
                    />
                  </Box>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  open={isMenuOpen}
                  onClose={() => setIsMenuOpen(false)}
                >
                  {menuItems.map(menuItem => (
                    <Menu.Item
                      key={menuItem.actionKey}
                      aria-label={`cell action menu item ${menuItem.actionKey}`}
                      onSelect={() => handleActionSelect(menuItem.actionKey)}
                      color={menuItem.danger ? 'danger' : 'primary'}
                    >
                      {menuItem.label}
                    </Menu.Item>
                  ))}
                </Menu>
              </>
            ) : (
              <Dash />
            )}
          </Box>
        )}
      </Box>
    </TD>
  );
};

export const ActionsRenderer = (props: RendererProps<'actions'>) => {
  const { formatMessage } = useIntl();
  const { i18nOverrides } = useContext(DataGridContext);
  if (props.loadingState === 'loading') {
    return (
      <TD width={80} pl={16} pr={16}>
        <Skeleton />
      </TD>
    );
  }
  const { cell } = props;
  const onActionSelect = (actionKey: ActionKey) =>
    props.config.onActionSelect(cell, actionKey);

  switch (cell.entityType) {
    case 'goal_actions': {
      const menuItems = [];
      if (cell.permissions.canEdit) {
        menuItems.push({
          label: formatMessage(messages.addKeyResult),
          actionKey: 'addKeyResult',
        });
        if (cell.initiativesFeature) {
          menuItems.push({
            label: formatMessage(messages.addInitiative, i18nOverrides),
            actionKey: 'addInitiative',
          });
        }
      }

      return (
        <Actions
          onSelect={onActionSelect}
          mode="inProgress"
          menuItems={menuItems}
        />
      );
    }
    case 'draft_goal_actions': {
      const menuItems = [];
      if (cell.permissions.canEdit) {
        menuItems.push({
          label: formatMessage(messages.addKeyResult),
          actionKey: 'addKeyResult',
        });
        if (cell.initiativesFeature) {
          menuItems.push({
            label: formatMessage(messages.addInitiative, i18nOverrides),
            actionKey: 'addInitiative',
          });
        }
      }
      return (
        <Actions onSelect={onActionSelect} mode="draft" menuItems={menuItems} />
      );
    }
    case 'key_result_actions':
      return (
        <Actions
          onSelect={onActionSelect}
          mode="inProgress"
          menuItems={
            cell.permissions.canEdit && cell.initiativesFeature
              ? [
                  {
                    label: formatMessage(messages.addInitiative, i18nOverrides),
                    actionKey: 'addInitiative',
                  },
                ]
              : []
          }
        />
      );
    case 'draft_key_result_actions':
      return (
        <Actions
          onSelect={onActionSelect}
          mode="noProgressChart"
          menuItems={
            cell.permissions.canEdit && cell.initiativesFeature
              ? [
                  {
                    label: formatMessage(messages.addInitiative, i18nOverrides),
                    actionKey: 'addInitiative',
                  },
                ]
              : []
          }
        />
      );
    case 'initiative_actions':
      return (
        <Actions
          onSelect={onActionSelect}
          mode="noProgressChart"
          menuItems={[]}
        />
      );

    case 'conversation_goal_actions':
    case 'conversation_key_result_actions':
    case 'conversation_aligned_goal_actions':
      return (
        <Actions
          onSelect={onActionSelect}
          mode="prepareConversation"
          menuItems={[]}
          talkingPointsCount={cell.talkingPointsCount}
          notesCount={cell.notesCount}
          showNotesInsteadOfTalkingPoints={cell.showNotesInsteadOfTalkingPoints}
          hideMenu
        />
      );
    case 'conversation_initiative_actions':
      return (
        <Actions
          onSelect={onActionSelect}
          mode="prepareConversationNoProgressChart"
          menuItems={[]}
          talkingPointsCount={cell.talkingPointsCount}
          notesCount={cell.notesCount}
          showNotesInsteadOfTalkingPoints={cell.showNotesInsteadOfTalkingPoints}
          hideMenu
        />
      );

    default: {
      // this is to make a missing Renderer a type error. see
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking
      // for how this works.
      const _exhaustiveCheck: never = cell;
      return _exhaustiveCheck;
    }
  }
};
